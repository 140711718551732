const glossaryLevel = [ 
    {
        name: "Word",
        key: "w"
    },
    {
        name: "Sentence",
        key: "s"
    },
    {
        name: "Phrase",
        key: "p"
    }
 ]

 export default glossaryLevel;